// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'Products',
          name: 'Products',
          component: () => import('@/views/pro/Index.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'ContactUs',
          name: 'ContactUs',
          component: () => import('@/views/sections/Contactus.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'About',
          name: 'About',
          component: () => import('@/views/sections/About.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Foodtech',
          name: 'Foodtech',
          component: () => import('@/views/sections/Foodtech.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Hotel',
          name: 'Hotel',
          component: () => import('@/views/sections/Hotel.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'GeneralIndus',
          name: 'GeneralIndus',
          component: () => import('@/views/sections/GeneralIndus.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Disinfectant',
          name: 'Disinfectant',
          component: () => import('@/views/sections/Disinfectant.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Cop',
          name: 'Cop',
          component: () => import('@/views/sections/Cop.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Cip',
          name: 'Cip',
          component: () => import('@/views/sections/Cip.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Washaddit',
          name: '세병첨가제',
          component: () => import('@/views/sections/Washaddit.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Conveyorlubricant',
          name: '컨베이어 윤활제',
          component: () => import('@/views/sections/Conveyorlubricant.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Bottlewaterplant',
          name: '생수공장용',
          component: () => import('@/views/sections/Bottlewaterplant.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Waterquality',
          name: '수질용',
          component: () => import('@/views/sections/Waterquality.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Automaticdishwashing',
          name: '자동식기 세척용',
          component: () => import('@/views/sections/Automaticdishwashing.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Kitchencleaner',
          name: '주방용 세척제',
          component: () => import('@/views/sections/Kitchencleaner.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Sanitarycleaner',
          name: '위생용 세척제',
          component: () => import('@/views/sections/Sanitarycleaner.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'Washingandcarpeting',
          name: '세탁 및 카페트용',
          component: () => import('@/views/sections/Washingandcarpeting.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'GeneralIndusagent',
          name: '일반 산업용 세척제',
          component: () => import('@/views/sections/GeneralIndusagent.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'FoodServiceGroupGuid',
          name: 'FoodServiceGroupGuid',
          component: () => import('@/views/sections/FoodServiceGroupGuid.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'FoodServiceGroup',
          name: 'FoodServiceGroup',
          component: () => import('@/views/sections/FoodServiceGroup.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
      ],
    },

  ],
})

export default router
